<template>
  <div class="fragment">

    <div class="section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['invoices_documentsUp',])"></div>
      {{ $t('invoices_documentsUp.localization_value.value') }}
    </div>
    
    <div class="custom-row" :key="images - 100">
      <div class="custom-col custom-col--16 custom-col--md-50 custom-col--sm-100"
           v-for="(item, index) in invoice.data.Files.data.downloadFiles"
           :key="index">
        <div class="site-document d-flex justify-content-center">
              <span class="site-document__remove" @click="removeDocument(item.smallImageId ? item.smallImageId : item.id)">
                <CloseIcon/>
              </span>
          <div class="site-document__img">
            <img v-if="item.mime_type !== 'application/pdf'"
                 :src="'data:image/png;base64,' + item.file"
                 @click="showSingle(item.bigImageId)"
                 alt="img"
            >
            <img v-else
                 @click="openPdfByUUID(item.uuid)"
                 style="width: 30px; height: 40px;"
                 src="/img/common/pdf_file_icon.svg"
                 alt="ico"
            >
          </div>
        </div>
      </div>

      <div class="custom-col" v-if="invoice.data.Files.data.maxCountFiles > invoice.data.Files.data.downloadFiles.length">
        <DropZone class="drop-zone-bg"
                  :parentFiles="invoice.data.Files.data.files"
                  :multiple="true"
                  :maxCount="invoice.data.Files.data.maxCountFiles - invoice.data.Files.data.downloadFiles.length"
                  :maxSize="invoice.data.Files.data.maxSizeFiles"
                  :accept="'image/x-png,image/gif,image/jpeg,application/pdf'"
                  @changeImg="changeImg"
        />
      </div>
    </div>

    <Lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
    ></Lightbox>
  </div>
</template>

<script>
import {filterFunc} from "../../../../../mixins/filterFuncMixin/filterFunc";
import {invoicesMixin} from "../../../../../mixins/invoicesMixins/invoicesMixin";
import DropZone from "../../../../coreComponents/DropZone/DropZone";
import CloseIcon from '../../../../../../public/img/modal-group/close-icon.svg?inline'
import Lightbox from "vue-easy-lightbox";

export default {
  name: "FilesBlock",

  components: {
    DropZone,
    CloseIcon,
    Lightbox,
  },

  mixins: [filterFunc, invoicesMixin],

  props: {
    invoice: Object,
    createData: Object,
    createPageFlag: {
      type: Boolean,
      default: false
    },
    transactionUser: Object,
  },

  data() {
    return {
      options: [],
      showTransactionInfo: false,
      images: -1,
      imgs: [], // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
    }
  },

  methods: {
    changeImg(files, maxSizeError) {
      this.invoice.data.Files.setFiles(files)
      this.invoice.data.Files.setFilesMaxSizeError(maxSizeError)
    },

    removeDocument(id) {
      this.$store.dispatch('removeUserDocument', id).then(() => {
        let files = this.invoice.data.Files
        let updatedFiles = this._.remove(files.data.downloadFiles, function(n) {
          return n?.id === id || n?.smallImageId === id
        })
        files.getDownloadFiles(updatedFiles)
        this.images = id
      })
    },

    show() {
      this.visible = true
    },

    handleHide() {
      this.visible = false
    },

    showSingle(id) {
      this.$store.dispatch('getFileFromServer', id).then((response) => {
        this.imgs = []
        console.log(response);
        this.imgs.push({
          title: 'img',
          src: `data:image/png;base64,` + response[id]
        })
        this.show()
      }).catch(error => this.createErrorLog(error))
    },

    openPdfByUUID(uuid) {
      let routeData = this.$router.resolve({
        path: this.$store.getters.GET_PATHS.getPdfFile + '/' + uuid
      })

      window.open(routeData.href, '_blank');
    },
  },
}
</script>