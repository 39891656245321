import {checkValid} from "../../../../globalModels/functions/ModelsValidate";
import {User} from "../../../../globalModels/User";
import {Files} from "../../../../globalModels/Files";
import {
    INVOICES_ARRAY_STATUSES,
    INVOICES_TYPES,
    INVOICES_MODELS, NOVAPOSHTA_DELIVERY_SERVICE, INVOICES_VARIANTS, CDEK_DELIVERY_SERVICE,
} from "../../../../../staticData/staticVariables";
import {PAYMENT_METHODS} from "../../../../../staticData/staticVariables";

export function Invoices() {

    this.checkValid = checkValid

    this.data = {
        id: '',
        totalAmount: '',
        storageCost: '',
        orderPrice: '',
        shippingCost: '',
        packagingCost: '',
        comment: '',
        status: '',
        ininialStatus: '',
        date: null,
        historyOfChanged: null,
        invoiceTypeId: null,
        invoiceVariant: null,

        User: new User(),
        Files: new Files({
            maxSizeFiles: 3,
            maxCountFiles: 5
        }),
    }

    this.validation = {
        id: false,
        totalAmount: false,
        storageCost: false,
        orderPrice: false,
        shippingCost: false,
        packagingCost: false,
        comment: false,
        status: false,
        ininialStatus: false,
        date: false,
        invoiceVariant: false,
    }

    this.validationTranslate = {
        id: '',
        totalAmount: '',
        storageCost: '',
        orderPrice: '',
        shippingCost: '',
        packagingCost: '',
        comment: '',
        status: '',
        ininialStatus: '',
        date: '',
        invoiceVariant: '',
    }

    this.validationTxt = {
        id: false,
        totalAmount: false,
        storageCost: false,
        orderPrice: false,
        shippingCost: false,
        packagingCost: false,
        comment: false,
        status: false,
        ininialStatus: false,
        date: false,
        invoiceVariant: false,
    }

    /**
     * Getters
     */
    this.getId = () => {
        return this.data.id
    }
    this.getTotalAmount = () => {
        return this.data.totalAmount
    }
    this.getStorageCost = () => {
        return this.data.storageCost
    }
    this.getOrderPrice = () => {
        return this.data.orderPrice
    }
    this.getShippingCost = () => {
        return this.data.shippingCost
    }
    this.getPackagingCost = () => {
        return this.data.packagingCost
    }
    this.getComment = () => {
        return this.data.comment
    }
    this.getStatus = () => {
        return this.data.status
    }
    this.getInitialStatus = () => {
        return this.data.ininialStatus
    }
    this.getDate = () => {
        return this.data.date
    }
    this.getHistoryOfChanged = () => {
        return this.data.historyOfChanged
    }
    this.getInvoiceTypeId = () => {
        return this.data.invoiceTypeId
    }
    this.getInvoiceVariant = () => {
        return this.data.invoiceVariant
    }

    /**
     * Setters
     */
    this.setId = (val) => {
        this.data.id = val
    }
    this.setTotalAmount = (val) => {
        this.data.totalAmount = val
    }
    this.setStorageCost = (val) => {
        this.data.storageCost = val
    }
    this.setOrderPrice = (val) => {
        this.data.orderPrice = val
    }
    this.setShippingCost = (val) => {
        this.data.shippingCost = val
    }
    this.setPackagingCost = (val) => {
        this.data.packagingCost = val
    }
    this.setComment = (val) => {
        this.data.comment = val
    }
    this.setStatus = (val) => {
        this.data.status = val
    }
    this.setInitialStatus = (val) => {
        this.data.ininialStatus = val
    }
    this.setDate = (val) => {
        this.data.date = val
    }
    this.setHistoryOfChanged = (val) => {
        this.data.historyOfChanged = val
    }
    this.setInvoiceTypeId = (val) => {
        this.data.invoiceTypeId = val
    }
    this.setInvoiceVariant = (val) => {
        this.data.invoiceVariant = val
    }
}

/**
 * Global Setters
 */

Invoices.prototype.setItem = function (item, that) {

    this.setId(item?.id)
    this.setTotalAmount(item?.total_amount)
    this.data.User.setUser(item?.user)
    this.setStorageCost(item?.storage_cost_amount)
    this.setOrderPrice(item?.order_price_amount)
    this.setShippingCost(item?.net_cost_amount)
    this.setPackagingCost(item?.packaging_amount)
    this.setComment(item?.comment || '')
    this.setStatus(that._.find(INVOICES_ARRAY_STATUSES, {name: item?.status}))
    this.setInitialStatus(this.getStatus())
    this.setDate(item?.created_at ? that.$moment(item?.closed_date).format('YYYY-MM-DD') : null)
    this.setHistoryOfChanged(item?.activityUUID)
    this.setInvoiceTypeId(item?.invoice_type_id)

    //order internal (np, cdek)
    if (item?.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_ORDER.id && item?.invoice_instance
        && item?.invoice_instance?.model === INVOICES_MODELS.INVOICES_MODEL_ORDER_INTERNAL.name) {

        if (item?.invoice_instance?.instance?.delivery_service_id === NOVAPOSHTA_DELIVERY_SERVICE.id) {
            this.setInvoiceVariant(INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_NP.name)
        }
        if (item?.invoice_instance?.instance?.delivery_service_id === CDEK_DELIVERY_SERVICE.id) {
            this.setInvoiceVariant(INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_CDEK.name)
        }
    }

    //order fbm
    if (item?.invoice_instance && item?.invoice_instance?.model === INVOICES_MODELS.INVOICES_MODEL_ORDER_FBM.name) {
        this.setInvoiceVariant(INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_FBM.name)
    }

    //express
    if (item?.invoice_instance && item?.invoice_instance?.model === INVOICES_MODELS.INVOICES_MODEL_ORDER_EXPRESS.name) {
        this.setInvoiceVariant(INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_EXPRESS.name)
    }

    //order fba
    if (item?.invoice_instance && item?.invoice_instance?.model === INVOICES_MODELS.INVOICES_MODEL_ORDER_FBA.name) {
        this.setInvoiceVariant(INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_FBA.name)
    }

    //order inbound (shipment)
    if (item?.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_ORDER.id &&
        item?.invoice_instance && item?.invoice_instance?.model === INVOICES_MODELS.INVOICES_MODEL_ORDER_INBOUND.name) {
        this.setInvoiceVariant(INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_INBOUND.name)
    }

    //other
    if (item?.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_STORAGE_USAGE.id || item?.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_CUSTOM.id
    || item?.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_DISPOSAL.id || item?.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_SMS.id) {
        this.setInvoiceVariant(INVOICES_VARIANTS.INVOICES_VARIANT_OTHER.name)
    }
}

/**
 * Prepare Data
 */

Invoices.prototype.prepareSave = function () {
    return {
        'user_id': this.data.User.getUserId(),
        'invoice_type_id': this.getInvoiceTypeId(),
        'invoice_payment_method_id': PAYMENT_METHODS.USER_BALANCE_METHOD.id,
        'storage_cost_amount': this.getStorageCost(),
        'order_price_amount': this.getOrderPrice(),
        'net_cost_amount': this.getShippingCost(),
        'packaging_amount': this.getPackagingCost(),
        'comment': this.getComment(),
        'status': this.getStatus()?.value,
        'invoice_date': this.getDate(),
    }
}

Invoices.prototype.prepareFilesData = function (id) {
    let readerArray = []
    this.data.Files.getFiles().map(item => {

        let reader = new FormData();
        reader.append("file", item.file);
        reader.append("complaint_id", id)
        readerArray.push(reader)
    })
    return readerArray
}

/**
 * Validations
 */

Invoices.prototype.firstValidationUser = function () {

    let validationItems = {
        status: this.getStatus(),
        date: this.getDate(),
    }

    let validationOptions = {
        status: {type: ['empty']},
        date: {type: ['empty']},
    }

    // only validate these fields if they are not empty
    if (this.getStorageCost()) {
        validationItems['storageCost'] = this.getStorageCost()
        validationOptions['storageCost'] = {type: ['numeric']}
    }
    if (this.getOrderPrice()) {
        validationItems['orderPrice'] = this.getOrderPrice()
        validationOptions['orderPrice'] = {type: ['numeric']}
    }
    if (this.getShippingCost()) {
        validationItems['shippingCost'] = this.getShippingCost()
        validationOptions['shippingCost'] = {type: ['numeric']}
    }
    if (this.getPackagingCost()) {
        validationItems['packagingCost'] = this.getPackagingCost()
        validationOptions['packagingCost'] = {type: ['numeric']}
    }

    if(!this.data.User.userValidation({userId: true}))
        return false

    return (this.checkValid(validationItems, validationOptions))
}
