<template>
  <div class="fragment">

    <div class="custom-row">
      <div class="custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'invoices_Storage',
          ])"></div>
        <DefaultInput
            :label="$t('invoices_Storage.localization_value.value')"
            v-bind:class="{'ui-no-valid': invoice.validation.storageCost}"
            :errorTxt="$t(`${invoice.validationTranslate.storageCost}.localization_value.value`)"
            :error="invoice.validation.storageCost"
            :disabled="disableFieldsFlag"
            v-model="invoice.data.storageCost"
        />
      </div>

      <div class="custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'invoices_Order',
          ])"></div>
        <DefaultInput
            :label="$t('invoices_Order.localization_value.value')"
            v-bind:class="{'ui-no-valid': invoice.validation.orderPrice}"
            :errorTxt="$t(`${invoice.validationTranslate.orderPrice}.localization_value.value`)"
            :error="invoice.validation.orderPrice"
            :disabled="disableFieldsFlag"
            v-model="invoice.data.orderPrice"
        />
      </div>
    </div>

    <div class="custom-row">
      <div class="custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'invoices_Shipping',
          ])"></div>
        <DefaultInput
            :label="$t('invoices_Shipping.localization_value.value')"
            v-bind:class="{'ui-no-valid': invoice.validation.shippingCost}"
            :errorTxt="$t(`${invoice.validationTranslate.shippingCost}.localization_value.value`)"
            :error="invoice.validation.shippingCost"
            :disabled="disableFieldsFlag"
            v-model="invoice.data.shippingCost"
        />
      </div>

      <div class="custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'invoices_Packaging',
          ])"></div>
        <DefaultInput
            :label="$t('invoices_Packaging.localization_value.value')"
            v-bind:class="{'ui-no-valid': invoice.validation.packagingCost}"
            :errorTxt="$t(`${invoice.validationTranslate.packagingCost}.localization_value.value`)"
            :error="invoice.validation.packagingCost"
            :disabled="disableFieldsFlag"
            v-model="invoice.data.packagingCost"
        />
      </div>
    </div>
    
    <div class="custom-row">
      <div class="custom-col">

        <template>
          <div class="section-label"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['invoices_Comment'])"></div>
            {{ $t('invoices_Comment.localization_value.value') }}
          </div>
          <div class="order-create__row custom-row">
            <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_widthCm'])"></div>
              <TextareaDefault
                  :label="$t('common_comment.localization_value.value')"
                  v-bind:class="{'ui-no-valid': invoice.validation.comment}"
                  :error="invoice.validation.comment"
                  :errorTxt="$t(`${invoice.validationTranslate.comment}.localization_value.value`)"
                  :disabled="disableFieldsFlag"
                  v-model="invoice.data.comment"
              />
            </div>
          </div>
        </template>

      </div>
    </div>

    <div class="custom-row">
      <div class="custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'invoices_invoiceStatus',
          ])"></div>
        <DefaultSelect
            class="w-100"
            :options="statuses"
            :label="$t('invoices_invoiceStatus.localization_value.value')"
            :otherValue="'translation'"
            v-bind:class="{'ui-no-valid': invoice.validation.status}"
            :errorTxt="$t(`${invoice.validationTranslate.status}.localization_value.value`)"
            :error="invoice.validation.status"
            @change="(val) => {invoice.setStatus(val)}"
            :selected="invoice.data.status"
        />
      </div>

      <div class="custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'invoices_Date',
          ])"></div>
        <DatePickerDefault
            :label="$t('invoices_Date.localization_value.value')"
            :placeholder="$t('common_dateYYYY_MM_DD.localization_value.value')"
            v-bind:class="{'ui-no-valid': invoice.validation.date}"
            :errorTxt="$t(`${invoice.validationTranslate.date}.localization_value.value`)"
            :error="invoice.validation.date"
            v-model="invoice.data.date"
        >
          <template slot="body">
            <date-picker
                v-model="invoice.data.date"
                :disabled="disableFieldsFlag"
                ref="datePicker"
                valueType="format"
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'YYYY-MM-DD'"
                :placeholder="$t('common_dateYYYY_MM_DD.localization_value.value')"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
import {filterFunc} from "../../../../../mixins/filterFuncMixin/filterFunc";
import TextareaDefault from "../../../../UI/textarea/TextareaDefault/TextareaDefault";
import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import DatePickerDefault from "../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
import DatePicker from 'vue2-datepicker';


export default {
  name: "FieldsBlock",

  components: {
    DatePicker,
    DatePickerDefault,
    DefaultSelect,
    TextareaDefault,
    DefaultInput,
  },

  mixins: [filterFunc],

  props: {
    invoice: Object,
    statuses: Array,
    disableFieldsFlag: Boolean,
  },

  mounted() {

  },

  data() {
    return {

    }
  },

  methods: {

  },
}
</script>