import {
  INVOICES_TYPES, INVOICES_VARIANTS,
} from "../../staticData/staticVariables";
export const invoicesMixin = {

  methods: {

    async addFiles(id, edit) {
      if(this.invoice.data.Files.getFiles().length > 0){
        await this.addCaseFiles(id)
      }

      if (edit) {
        this.openNotify('success', 'common_notificationRecordChanged')
      }
      else {
        this.openNotify('success', 'common_notificationRecordCreated')
      }

        this.$router.push(this.$store.getters.GET_PATHS.financeInvoices)

    },

    save(edit = false) {

      if(!this.invoice.firstValidationUser()
          || (!this.invoice.data.Files.firstValidation() && this.invoice.data.Files.getFiles().length > 0)
      ) return

      let data = this.invoice.prepareSave(edit)

      let saveType = 'createCustomInvoice'

      if (edit) {
        data = {
          id: this.invoice.getId(),
          data: data
        }
        if(data.invoice_type_id !== INVOICES_TYPES.INVOICES_TYPE_CUSTOM.id) {
          saveType = 'updateInvoices'
        } else {
          saveType = 'updateCustomInvoice'
        }

      }
      else {
        data['invoice_type_id'] = INVOICES_TYPES.INVOICES_TYPE_CUSTOM.id
      }

      console.log(data);
      console.log(saveType);

      this.$store.dispatch(saveType, data).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            if (!edit) {
              this.invoice.setId(response.data.data.invoice.id)
            }
            if (this.invoice.data.Files.getFiles().length > 0) {
              this.createFiles(this.invoice.getId())
              return
            }

            if (edit) {
              this.openNotify('success', 'common_notificationRecordChanged')
            } else {
              this.openNotify('success', 'common_notificationRecordCreated')
              //because we can only create custom invoices, redirect on other tab
              this.$router.push(this.$store.getters.GET_PATHS.financeInvoices + '?page=1&count=25&type=other')
              break
            }

            switch (this.invoice.data.invoiceVariant) {
              case INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_NP.name: {
                this.$router.push(this.$store.getters.GET_PATHS.financeInvoices + '?page=1&count=25&type=novaPoshta')
                break
              }
              case INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_CDEK.name: {
                this.$router.push(this.$store.getters.GET_PATHS.financeInvoices + '?page=1&count=25&type=cdek')
                break
              }
              case INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_FBM.name: {
                this.$router.push(this.$store.getters.GET_PATHS.financeInvoices + '?page=1&count=25&type=orders')
                break
              }
              case INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_FBA.name: {
                this.$router.push(this.$store.getters.GET_PATHS.financeInvoices + '?page=1&count=25&type=FBA')
                break
              }
              case INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_INBOUND.name: {
                this.$router.push(this.$store.getters.GET_PATHS.financeInvoices + '?page=1&count=25&type=inbound')
                break
              }
              case INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_EXPRESS.name: {
                this.$router.push(this.$store.getters.GET_PATHS.financeInvoices + '?page=1&count=25&type=DHLFedExTNT')
                break
              }
              case INVOICES_VARIANTS.INVOICES_VARIANT_CONSOLIDATION.name: {
                this.$router.push(this.$store.getters.GET_PATHS.financeInvoices + '?page=1&count=25&type=consolidation')
                break
              }
              case INVOICES_VARIANTS.INVOICES_VARIANT_OTHER.name: {
                this.$router.push(this.$store.getters.GET_PATHS.financeInvoices + '?page=1&count=25&type=other')
                break
              }
              default:
                this.$router.push(this.$store.getters.GET_PATHS.financeInvoices + '?page=1&count=25')
                break
            }

            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

      })

    },

    createFiles(invoiceId) {
      // let data = {}
      let reader = new FormData();
      this.invoice.data.Files.data.files.map((item, index) => {
        if(!this._.has(item, 'maxCount') && !item.maxSizeError){
          reader.append("file"+(index+1), item.file);
        }
      })
      reader.append("invoice_id", invoiceId);

      this.$store.dispatch('uploadFilesForInvoice', reader).then((response) => {

        if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
          this.openNotify('success', 'common_notificationRecordCreated')

          switch (this.invoice.data.invoiceVariant) {
            case INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_NP.name: {
              this.$router.push(this.$store.getters.GET_PATHS.financeInvoices + '?page=1&count=25&type=novaPoshta')
              break
            }
            case INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_CDEK.name: {
              this.$router.push(this.$store.getters.GET_PATHS.financeInvoices + '?page=1&count=25&type=cdek')
              break
            }
            case INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_FBM.name: {
              this.$router.push(this.$store.getters.GET_PATHS.financeInvoices + '?page=1&count=25&type=orders')
              break
            }
            case INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_FBA.name: {
              this.$router.push(this.$store.getters.GET_PATHS.financeInvoices + '?page=1&count=25&type=FBA')
              break
            }
            case INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_INBOUND.name: {
              this.$router.push(this.$store.getters.GET_PATHS.financeInvoices + '?page=1&count=25&type=inbound')
              break
            }
            case INVOICES_VARIANTS.INVOICES_VARIANT_ORDER_EXPRESS.name: {
              this.$router.push(this.$store.getters.GET_PATHS.financeInvoices + '?page=1&count=25&type=DHLFedExTNT')
              break
            }
            case INVOICES_VARIANTS.INVOICES_VARIANT_CONSOLIDATION.name: {
              this.$router.push(this.$store.getters.GET_PATHS.financeInvoices + '?page=1&count=25&type=consolidation')
              break
            }
            case INVOICES_VARIANTS.INVOICES_VARIANT_OTHER.name: {
              this.$router.push(this.$store.getters.GET_PATHS.financeInvoices + '?page=1&count=25&type=other')
              break
            }
            default:
              this.$router.push(this.$store.getters.GET_PATHS.financeInvoices + '?page=1&count=25')
              break
          }

        } else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
          alert('novalid')
        } else {
          this.openNotify('error', 'common_notificationUndefinedError')
        }

      }).catch(error => this.createErrorLog(error))
    },
  }

}
